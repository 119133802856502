<template>
  <b-card title="">
    <b-row>
      <b-col cols="6">
        <h3 class="text-primary">Edit Cloud Profile</h3>
      </b-col>
      <b-col cols="6">
        <b-button
          variant="primary"
          class="btn btn-primary float-right"
          @click="back()"
          size="sm"
        >
          Back
        </b-button>
      </b-col>
      <b-col cols="12">
        <validation-observer ref="simpleRules" #default="{ invalid }">
          <b-form @submit.prevent>
            <b-row>
              <!-- first name -->
              <b-col cols="6">
                <b-form-group label="Cloud Name" label-for="v-first-name">
                  <validation-provider
                    #default="{ errors }"
                    name="Cloud Name"
                    rules="required"
                  >
                    <b-form-input
                      id="v-first-name"
                      placeholder="Cloud Name"
                      v-model="cloudName"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Profile Type" label-for="v-first-name">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Groups"
                    rules="required"
                  > -->
                  <b-form-select
                    v-model="profileType"
                    :options="profileOptions"
                  >
                  </b-form-select>
                  <!-- {{ profileType }} -->

                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Access Key" label-for="v-first-name">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Access Key"
                    rules="required"
                  > -->
                    <b-form-input
                      id="v-first-name"
                      placeholder="Access Key"
                      v-model="accessKey"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Secret Key" label-for="v-first-name">
                    <b-form-input
                      id="v-first-name"
                      placeholder="Secret Key"
                      v-model="secretKey"
                    />
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
              <b-col cols="12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1 mt-1"
                  @click.prevent="updateCloudProfile(cloudid)"
                  :disabled="invalid"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
// import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BCard,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      cloudName: "",
      profileType: "",
      profileOptions: [
        { value: "AWS", text: "AWS" },
        { value: "DGO", text: "Digital Ocean" },
        { value: "LightSail", text: "AWS LightSail" },
      ],
      // firewallId: "",
      accessKey: "",
      secretKey: "",
      token: "",
      items: [],
      cloudList: [],
      cloudid: "",
    };
  },
  mounted() {
    // this.fetchGroup();
    this.getCloudList();
    this.token = this.$store.state.app.token;
  },
  created() {
    // (this.tenantid = this.$store.getters.tenantid),
    //   (this.accesstoken = this.$store.getters.token);
    //   console.log(this.tenantid);
    this.cloudid = this.$route.params.id;
  },
  methods: {
    back() {
      this.$router.push("/eca-cloud");
    },
    getCloudList: function (cloudid) {
      const options = {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url:
          process.env.VUE_APP_BASEURL +
          `cloudprofile/cloudprofile/${this.cloudid}/`,
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          console.log(res.data);
          this.cloudList = res.data;
          this.cloudName = res.data.name;
          this.profileType = res.data.profile_type;
          // this.accessKey = res.data.access_key;
          // this.secretKey = res.data.secret_key;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateCloudProfile(cloudid) {
      var self = this;
      let data = {
        name: this.cloudName,
        profile_type: this.profileType,
        access_key: this.accessKey,
        secret_key: this.secretKey,
        id: this.cloudid,
      };
      console.table(data, "dataa");
      const options = {
        method: "PUT",
        data: data,
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url:
          process.env.VUE_APP_BASEURL +
          `cloudprofile/cloudprofile/${this.cloudid}/`,
      };
      this.$http(options)
        .then((res) => {
            if (res.data.status === "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Edited Successfully",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.$router.push("/eca-cloud");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
